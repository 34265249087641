
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "@/components/BaseButton.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextMessageError from "@/components/BaseTextMessageError.vue";
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Footer from "@/components/Footer.vue";

import { namespace } from "vuex-class";
import { detectMobile } from "@/utils/Functions";

const UserStore = namespace("UserStore");

@Component({
  components: {
    BaseButton,
    BaseCard,
    BaseInput,
    BaseTextMessageError,
    Header,
    HeaderMenu,
    Footer,
  },
})
export default class Login extends Vue {
  email = "";
  password = "";
  errorMessage = "";

  @UserStore.Mutation
  // eslint-disable-next-line
  private loginSuccess!: (data: any) => Promise<void>;

  @UserStore.Getter
  // eslint-disable-next-line
  private isLoggedIn!: boolean;

  isValidInput(): boolean {
    this.errorMessage = "";

    if (!this.email) {
      this.errorMessage = "Required email";
      return false;
    }

    if (this.password.length < 8) {
      this.errorMessage = "Password must contain at least 8 characters";
      return false;
    }

    return true;
  }

  handleLoginResponde(data: any): void {
    if (data.firstAccess) {
      this.$router.push({
        path: "/setting/change/password",
        query: { firstAccess: "true" },
      });
      return;
    }
    this.$router.push("/home");
  }

  async login(): Promise<void> {
    if (!this.isValidInput()) return;

    const accessSubtype = detectMobile()
      ? "MOBILE_WEB_LOGIN"
      : "DESKTOP_WEB_LOGIN";

    try {
      const { data } = await this.$api.post("/library/auth", {
        email: this.email,
        password: this.password,
        accessSubtype: accessSubtype,
      });

      await this.loginSuccess(data);
      this.handleLoginResponde(data);
    } catch (error) {
      console.error(error);
    }
  }

  recoverPassword(): void {
    this.$router.push("/password-reset-step-one");
  }
}
