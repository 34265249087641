
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SocialMidiaIcone extends Vue {
  @Prop({ default: "" }) defaulIcone?: string;
  @Prop({ default: "" }) onHoverIcon?: string;
  @Prop({ default: "" }) href?: string;
  @Prop({ default: "" }) title?: string;
}
