import axios from "axios";
import Vue from "vue";
import store from "../store";
import { apiURL } from "./config";

const api = axios.create({
  baseURL: apiURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

//api.defaults.timeout = 5000;

api.interceptors.request.use(
  (config) => {
    const isLoggedId = store.state.UserStore.status.loggedIn;
    if (isLoggedId) {
      config.headers.common[
        "Authorization"
      ] = `Bearer ${store.state.UserStore.user.access_token}`;
    }
    Vue.swal({
      text: "Loading",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    Vue.swal.showLoading();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    Vue.swal.close();
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response) {
      const errorMessage = "Não foi possível acessar a informação solicitada";

      const { data } = error.response;

      const message = data.message
        ? processMessage(data.message)
        : errorMessage;

      Vue.swal({ icon: "error", text: message });

      return Promise.reject({ ...data, message });
    }

    Vue.swal({
      icon: "error",
      text: "Não foi possível acessar o servidor." + error.message,
    });

    return Promise.reject(error);
  }
);

function processMessage(message: string | Array<string>) {
  if (typeof message === "string") {
    return message;
  }
  return message.reduce((acc, m) => `${acc}\n${m}`);
}
export default api;
