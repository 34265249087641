export const formatIOSDateToMMDDYYYY = (IOSDate: string): string => {
  if (!IOSDate) {
    return "";
  }
  const date = new Date(IOSDate);
  const year = date.getFullYear();
  let month: number | string = date.getMonth() + 1;
  let dt: number | string = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  const formatedDate = month + "/" + dt + "/" + year;

  return formatedDate;
};

export const detectMobile = (): boolean => {
  return navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
    ? true
    : false;
};

export const getQuarter = (month: string): string => {
  const parseMonth = parseInt(month);
  if (parseMonth < 1 || parseMonth > 12) return "Q?";

  const quarter =
    parseMonth < 4
      ? 1
      : parseMonth < 7
      ? 2
      : parseMonth < 10
      ? 3
      : parseMonth < 13
      ? 4
      : 0;

  return `Q${quarter}`;
};
