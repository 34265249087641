
import { Component, Vue } from "vue-property-decorator";

export interface MenuItemDTO {
  title: string;
  libraryPageParam: string;
  hasSubcategory?: boolean;
}

@Component({
  components: {},
})
export default class HeaderMenu extends Vue {
  showMenu = false;
  showSubmenu = false;

  headerMenuItem: Array<MenuItemDTO> = [
    {
      title: "Investment strategy",
      libraryPageParam: "/home/brain-library/investment_strategy",
    },
    {
      title: "Markets and managers updates",
      libraryPageParam: "/home/brain-library/market_update",
    },
    {
      title: "ESG",
      libraryPageParam: "/home/brain-library/esg",
    },
    {
      title: "Alternative investments",
      libraryPageParam: "/home/brain-library/alternative_investments",
      hasSubcategory: true,
    },
  ];

  headerMenuSubItem: Array<MenuItemDTO> = [
    {
      title: "Private debt",
      libraryPageParam: "/home/brain-library/private_debt",
    },
    {
      title: "Private equity",
      libraryPageParam: "/home/brain-library/private_equity",
    },
    {
      title: "Real estate",
      libraryPageParam: "/home/brain-library/real_estate",
    },
    {
      title: "Specialty finance",
      libraryPageParam: "/home/brain-library/specialty_finance",
    },
    {
      title: "Venture capital",
      libraryPageParam: "/home/brain-library/venture_capital",
    },
  ];

  get cssClass(): string {
    return this.showSubmenu ? "img-rotate" : "";
  }

  togle(): void {
    this.showMenu = !this.showMenu;
    if (!this.showMenu) this.showSubmenu = false;
  }

  togleSubmenu(): void {
    this.showSubmenu = !this.showSubmenu;
  }
}
