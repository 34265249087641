
import { Component, Vue } from "vue-property-decorator";
import SocialMidiaIcone from "@/components/SocialMidiaIcone.vue";

export interface LinkItem {
  title: string;
  libraryPageParam: string;
}
@Component({
  components: { SocialMidiaIcone },
})
export default class Footer extends Vue {
  linkItems: Array<LinkItem> = [
    {
      title: "Investment strategy",
      libraryPageParam: "/home/brain-library/investment_strategy",
    },
    {
      title: "Markets and managers updates",
      libraryPageParam: "/home/brain-library/market_update",
    },
    {
      title: "Alternative investments",
      libraryPageParam: "/home/brain-library/alternative_investments",
    },
  ];

  linkSubItems: Array<LinkItem> = [
    {
      title: "Private debt",
      libraryPageParam: "/home/brain-library/private_debt",
    },
    {
      title: "Private equity",
      libraryPageParam: "/home/brain-library/private_equity",
    },
    {
      title: "Real estate",
      libraryPageParam: "/home/brain-library/real_estate",
    },
    {
      title: "Specialty finance",
      libraryPageParam: "/home/brain-library/specialty_finance",
    },
    {
      title: "Venture capital",
      libraryPageParam: "/home/brain-library/venture_capital",
    },
  ];
}
