import "sweetalert2/dist/sweetalert2.min.css";
import Vue from "vue";
import VModal from "vue-js-modal";
import VueSweetalert2 from "vue-sweetalert2";
import VueTheMask from "vue-the-mask";
import VueApexCharts from "vue-apexcharts";
import App from "./App.vue";
import "./assets/css/bootstrap-grid.css";
import "./assets/css/bootstrap-utilities.css";
import "./assets/css/main.css";

import router from "./router";
import store from "./store";
import api from "./api/api";

Vue.use(VueApexCharts);

Vue.component("apexcharts", VueApexCharts);

Vue.use(VModal);

Vue.prototype.$api = api;

const sweetAlertOptions = {
  confirmButtonColor: "#0fb350",
  confirmButtonText: "Ok",

  cancelButtonColor: "#ef0000",
  cancelButtonText: "Não",
};
Vue.use(VueSweetalert2, sweetAlertOptions);
Vue.use(VueTheMask);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
