import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { InvestorDTO } from "./../../interfaces/Investor.dto";

@Module({ namespaced: true })
class InvestorStore extends VuexModule {
  public selectedInvestors: Array<InvestorDTO> = [];

  @Mutation
  public markSelectedInvestors(selectedInvestors: Array<InvestorDTO>): void {
    this.selectedInvestors = selectedInvestors;
  }
}
export default InvestorStore;
