
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseInput extends Vue {
  @Prop() value?: string;
  @Prop() title?: string;
  @Prop() placeholder?: string;
  @Prop({ default: "text" }) inputType?: string;

  focus(): void {
    // eslint-disable-next-line
    const baseInput: any = this.$refs.baseInput;
    if (baseInput) {
      baseInput.focus();
    }
  }
}
