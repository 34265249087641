
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "@/components/BaseButton.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextMessageError from "@/components/BaseTextMessageError.vue";
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Footer from "@/components/Footer.vue";
import PageTitleBar from "@/components/PageTitleBar.vue";

import { namespace } from "vuex-class";

const UserStore = namespace("UserStore");

@Component({
  components: {
    BaseButton,
    BaseCard,
    BaseInput,
    BaseTextMessageError,
    Header,
    HeaderMenu,
    Footer,
    PageTitleBar,
  },
})
export default class PasswordResetStepOne extends Vue {
  email = "";
  errorMessage = "";

  @UserStore.Mutation
  // eslint-disable-next-line
  private loginSuccess!: (data: any) => Promise<void>;

  isValidInput(): boolean {
    this.errorMessage = "";

    if (!this.email) this.errorMessage = "Required email";

    return !this.errorMessage;
  }

  async resetPassword(): Promise<void> {
    if (!this.isValidInput()) return;

    try {
      const { data } = await this.$api.post(
        "/app/auth-investor/forgot-password",
        {
          email: this.email,
        }
      );

      this.$router.push({
        name: "passwordResetSteTwo",
        params: { email: this.email },
      });
    } catch (error) {
      console.error(error);
    }
  }

  recoverPassword(): void {
    console.log("Recover Password...");
  }

  onGoToLoginClick(): void {
    this.$router.push("/login");
  }
}
