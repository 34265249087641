import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import store from "../store";
import PasswordResetStepOne from "../views/PasswordResetStepOne.vue";
import PasswordResetStepTwo from "../views/PasswordResetStepTwo.vue";
import Login from "../views/Login.vue";
import jwt_decode from "jwt-decode";

Vue.use(VueRouter);

function isTokenExpired(token: string): boolean {
  const { exp }: any = jwt_decode(token);
  if (Date.now() >= exp * 1000) {
    return true;
  }
  return false;
}

const isAuthenticated = (_to: Route, _from: Route, next:any) => {
  const isLoggedId = store.state.UserStore.status.loggedIn;

  if (isLoggedId) {
    next("/");
    return;
  }
  next();
};

const routes: Array<RouteConfig> = [
  {
    meta: { requiresLogin: true },
    path: "/",
    redirect: "/home",
    component: () => import("../views/Layout.vue"),
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("../layout/Home.vue"),
      },
      {
        path: "/home/brain-library/:libraryType/:postId/:postTitle",
        name: "PostDetails",
        component: () => import("../layout/PostDetails.vue"),
        props: true,
      },
      {
        path: "/home/brain-library/:libraryType",
        name: "LibraryTypePage",
        component: () => import("../layout/LibraryTypePage.vue"),
        props: true,
      },
      {
        path: "/home/investments-philosophy",
        name: "InvestmentsPhilosophy",
        component: () => import("../layout/InvestmentsPhilosophy.vue"),
      },
      {
        path: "/home/brain-library/search-result/search-result",
        name: "SearchResult",
        component: () => import("../layout/SearchResult.vue"),
      },
      {
        path: "/setting/change/password",
        name: "ChangePassword",
        component: () => import("../layout/ChangePassword.vue"),
      },
    ],
    beforeEnter: (_to: Route, _from: Route, next) => {

      const isLoggedId = store.state.UserStore.status.loggedIn;

      if (isLoggedId) {
        const token = store.state.UserStore.user.access_token;
        if (isTokenExpired(token)) {
          store.commit("UserStore/logout");
          next("./login");
        }
      }

      if (!isLoggedId) next("./login");
      else next();
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter:isAuthenticated
  },
  {
    path: "/password-reset-step-one",
    name: "passwordResetStepOne",
    component: PasswordResetStepOne,
    beforeEnter:isAuthenticated
  },
  {
    path: "/password-reset-step-two",
    name: "passwordResetSteTwo",
    component: PasswordResetStepTwo,
    beforeEnter:isAuthenticated
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
