
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class BaseButton extends Vue {
  @Prop({ default: "secondary" }) private color!: string;
  @Prop({ default: false }) private disabled!: boolean;

  get cssClass(): string {
    return `base-button base-button-${this.color}`;
  }
}
