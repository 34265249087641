import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class ChatStore extends VuexModule {
  public chat = { quantNotifications: 0 };

  @Mutation
  public newNotification(): void {
    if (this.chat.quantNotifications < 100) {
      this.chat.quantNotifications = ++this.chat.quantNotifications;
    }
  }

  @Mutation
  public resetNotifications(): void {
    this.chat.quantNotifications = 0;
  }

  get quantNotifications(): number {
    return this.chat.quantNotifications;
  }
}
export default ChatStore;
