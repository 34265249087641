
import { Component, Vue } from "vue-property-decorator";
import HeaderMenu from "@/components/HeaderMenu.vue";
import BaseTextMessageError from "@/components/BaseTextMessageError.vue";

import { UserDTO } from "../interfaces/UserEntity.dto";

import { namespace } from "vuex-class";
const UserStore = namespace("UserStore");

@Component({
  components: {
    HeaderMenu,
    BaseTextMessageError,
  },
})
export default class Header extends Vue {
  @UserStore.Getter
  private isLoggedIn!: boolean;

  @UserStore.State
  private user!: UserDTO;

  @UserStore.Mutation
  private logout!: () => void;

  openMenu = false;
  navOpen = true;

  handleClickMenuIcon(): void {
    this.openMenu = !this.openMenu;
    (this.$refs.headerMenu as any).togle();
  }

  onSettingIconClick(): void {
    this.$router.push("/setting/change/password");
  }

  onLogoutClick(): void {
    this.logout();
    this.$router.replace("/login");
  }

  onLoginClick(): void {
    this.$router.push("/login").catch(() => {
      console.log("same route");
    });
  }

  get showUserLogedInfos(): boolean {
    return this.isLoggedIn ? true : false;
  }
  get username(): string {
    return this.isLoggedIn ? this.user.name.split(" ")[0] : "";
  }
}
