import Vue from "vue";
import Vuex from "vuex";
import InvestorStore from "./modules/investor.store";
import StaticTypesStore from "./modules/static-types.store";
import UserStore from "./modules/user.store";
import ChatStore from "./modules/chat.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    InvestorStore,
    StaticTypesStore,
    UserStore,
    ChatStore,
  },
});
