import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { UserDTO } from "../../interfaces/UserEntity.dto";

const USER_STORAGE_KEY = "BRAIN_LIBRARY_USER";

const storedUser = localStorage.getItem(USER_STORAGE_KEY);

@Module({ namespaced: true })
class UserStore extends VuexModule {
  public status = storedUser ? { loggedIn: true } : { loggedIn: false };

  public user: UserDTO | null = storedUser ? JSON.parse(storedUser) : null;

  @Mutation
  public loginSuccess(user: UserDTO): void {
    this.status.loggedIn = true;
    this.user = user;
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
  }

  @Mutation
  public loginFailure(): void {
    this.status.loggedIn = false;
    this.user = null;
    localStorage.removeItem(USER_STORAGE_KEY);
  }

  @Mutation
  public logout(): void {
    this.status.loggedIn = false;
    this.user = null;
    localStorage.removeItem(USER_STORAGE_KEY);
  }

  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }

  get accessToken(): string {
    if (!this.user) return "";
    return this.user.access_token;
  }
  get userId(): string {
    if (!this.user) return "";
    return this.user.id;
  }
}
export default UserStore;
