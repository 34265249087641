
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "@/components/BaseButton.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextMessageError from "@/components/BaseTextMessageError.vue";
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Footer from "@/components/Footer.vue";
import PageTitleBar from "@/components/PageTitleBar.vue";

import { namespace } from "vuex-class";

const UserStore = namespace("UserStore");

@Component({
  components: {
    BaseButton,
    BaseCard,
    BaseInput,
    BaseTextMessageError,
    Header,
    HeaderMenu,
    Footer,
    PageTitleBar,
  },
})
export default class PasswordResetStepOne extends Vue {
  email = "";
  errorMessage = "";

  code = "";
  password = "";
  comfirmPassword = "";

  step = 1;

  @UserStore.Mutation
  // eslint-disable-next-line
  mounted(): void {
    this.email = this.$route.params.email;
    !this.email ? this.$router.push("/login") : "";
  }

  isValidInput(): boolean {
    this.errorMessage = "";

    if (!this.code) {
      this.errorMessage = "Required Code";
      return false;
    }
    if (!this.password || !this.comfirmPassword) {
      this.errorMessage = "Required passwords";
      return false;
    }
    if (this.password != this.comfirmPassword) {
      this.errorMessage =
        "New password is different from Password confirmation";
      return false;
    }

    return true;
  }

  async resetPassword(): Promise<void> {
    if (!this.isValidInput()) return;

    try {
      const { data } = await this.$api.post(
        "/app/auth-investor/change-password",
        {
          email: this.email,
          otgCode: this.code.trim(),
          password: this.password,
        }
      );
      this.step = 3;
    } catch (error) {
      console.error(error);
    }
  }
  onButtonClick(): void {
    if (this.step === 1) {
      this.step = 2;
    } else if (this.step === 2) {
      this.resetPassword();
    } else if (this.step === 3) {
      this.$router.push("/login");
    }
  }

  onGoToLoginClick(): void {
    this.$router.push("/login");
  }

  get buttonText(): string {
    if (this.step === 1) return "Continue";
    else if (this.step === 2) {
      return "To send";
    }
    return "Log into";
  }

  get userMessageText(): string {
    if (this.step === 1)
      return "A recovery code has been sent to the email provided.";
    else if (this.step === 2) {
      return "";
    }
    return "Password changed successfully.";
  }
}
